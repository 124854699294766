/* General Styling */
body, html {
    font-family: 'Roboto', sans-serif;
    background-color: #1c1e24;
    color: #f5f5f5;
    line-height: 1.6;
}

.container {
    background: #22252a;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

h2, h3 {
    text-align: center;
    color: #2ec4b6;
}

h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

h3 {
    font-size: 1.8rem;
    margin-top: 30px;
    margin-bottom: 15px;
}

/* Form Styling */
form {
    background: #2a2d35;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

input[type="text"],
input[type="number"],
input[type="date"],
select,
input[type="file"],
textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #444;
    border-radius: 6px;
    background-color: #2e323a;
    color: #fff;
    outline: none;
    margin-bottom: 10px;
    font-size: 1rem;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="date"]:focus,
select:focus,
input[type="file"]:focus,
textarea:focus {
    background-color: #3b4049;
    border-color: #2ec4b6;
}

input[type="file"] {
    padding: 5px;
    cursor: pointer;
}

button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 10px 5px 0;
}

button.bg-green-500 {
    background-color: #38a169;
}

button.bg-green-500:hover {
    background-color: #2f855a;
}

button.bg-blue-500 {
    background-color: #3182ce;
}

button.bg-blue-500:hover {
    background-color: #2b6cb0;
}

button.bg-red-500 {
    background-color: #e53e3e;
}

button.bg-red-500:hover {
    background-color: #c53030;
}

button:disabled {
    background-color: #555;
    cursor: not-allowed;
}

/* Table Styling */
table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
}

th, td {
    padding: 10px;
    text-align: center;
    border: 1px solid #3b4049;
}

th {
    background: #2e323a;
    color: #ffffff;
    font-weight: bold;
    font-size: 1.1rem;
    position: sticky;
    top: 0;
    z-index: 1;
}

td {
    background: #1f2329;
}

tr:nth-child(even) td {
    background: #24282f;
}

tr:hover td {
    background: #2c3038;
}

.hover\\:bg-dark-hover:hover {
    background: #2ec4b6;
    color: #1c1e24;
}

.text-center {
    text-align: center;
}

.text-gray-400 {
    color: #9a9a9a;
}

/* Icons */
button::before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    margin-right: 8px;
}

button.bg-green-500::before {
    content: '\f0fe'; /* Plus Icon */
}

button.bg-blue-500::before {
    content: '\f019'; /* Download Icon */
}

button.bg-red-500::before {
    content: '\f1f8'; /* Trash Icon */
}

button.bg-blue-500[onClick*="handleEdit"]::before {
    content: '\f044'; /* Edit Icon */
}

/* File Input Styling */
input[type="file"] {
    display: inline-block;
    width: auto;
    background-color: #2e323a;
    color: #fff;
    padding: 6px 12px;
    font-size: 0.9rem;
    border-radius: 6px;
}

/* Responsive Design */
@media (max-width: 768px) {
    h2, h3 {
        font-size: 1.8rem;
    }

    .grid {
        grid-template-columns: 1fr;
    }

    input, select, textarea {
        width: 100%;
        margin-bottom: 15px;
    }

    button {
        width: 100%;
        margin-bottom: 10px;
    }

    table th, table td {
        font-size: 0.85rem;
        padding: 8px;
    }
}

/* Font and Icon Imports */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');
