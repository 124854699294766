/* General Styles */
body, html {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f4f4f9;
    color: #333;
    margin: 0;
    padding: 0;
}

/* Container */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #1e1e2f;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Titles */
h2, h3 {
    text-align: center;
    color: #4fc3f7;
    margin-bottom: 20px;
}

/* Form Styles */
form {
    background-color: #282a36;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}

form .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 20px;
}

input, select, textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    background-color: #222436;
    color: #eee;
    border: 1px solid #444;
    border-radius: 4px;
    outline: none;
    transition: border 0.3s;
}

input:focus, select:focus, textarea:focus {
    border-color: #4fc3f7;
}

/* Buttons */
button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button[type="submit"] {
    background-color: #4caf50;
}

button[type="submit"]:hover {
    background-color: #388e3c;
}

button[type="button"] {
    background-color: #2196f3;
    margin-left: 10px;
}

button[type="button"]:hover {
    background-color: #1976d2;
}

.bg-green-500:hover {
    background-color: #388e3c !important;
}

.bg-blue-500:hover {
    background-color: #1976d2 !important;
}

/* Table Styles */
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    overflow-x: auto;
}

thead {
    background-color: #34354a;
}

th, td {
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid #444;
}

th {
    color: #4fc3f7;
}

tbody tr {
    transition: background-color 0.2s;
}

tbody tr:hover {
    background-color: #34354a;
}

.bg-dark-hover:hover {
    background-color: #34354a !important;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    form .grid {
        grid-template-columns: 1fr;
    }

    table {
        font-size: 14px;
    }

    th, td {
        padding: 8px;
    }

    .container {
        padding: 10px;
    }

    button {
        width: 100%;
        margin-top: 10px;
    }
}

/* Input Styles */
.bg-dark-input {
    background-color: #222436;
    color: #eee;
}

.bg-dark-input:focus {
    border-color: #4fc3f7;
}

/* Checkbox Label */
.flex {
    display: flex;
    align-items: center;
    color: #eee;
}

.flex input[type="checkbox"] {
    margin-right: 10px;
}

/* File Upload Button */
input[type="file"] {
    padding: 4px;
    cursor: pointer;
    background-color: #282a36;
}

/* Scrollbar Customization */
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #222436;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #4fc3f7;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #377ab1;
}
