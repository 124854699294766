body {
  font-family: 'Poppins', Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #0d0d0d; /* Darker background for better contrast */
  color: #fff;
}

/* Navigation Bar */
nav {
  background-color: #222;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  padding: 10px 0;
  z-index: 100;
  position: sticky;
  top: 0;
}

nav a {
  padding: 10px 20px;
  margin: 0 10px;
  color: white;
  text-decoration: none;
  font-weight: 600;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

nav a:hover {
  background-color: #ff9800;
  color: #0d0d0d;
}

/* Hero Section */
header {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 80vh;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

header h1 {
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 15px;
  background: rgba(0, 0, 0, 0.6);
  padding: 15px 25px;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
}

header p {
  font-size: 1.5rem;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  border-radius: 8px;
  margin-top: 10px;
}

/* Social Media Buttons */
.social-media-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin: 20px 0;
}

.social-media-buttons .btn {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 12px 25px;
  font-weight: bold;
  border-radius: 50px;
  transition: transform 0.3s, background-color 0.3s;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.social-media-buttons .btn:hover {
  transform: scale(1.1);
  opacity: 0.95;
}

.social-media-buttons .whatsapp-btn {
  background-color: #25D366;
}

.social-media-buttons .facebook-btn {
  background-color: #4267B2;
}

.social-media-buttons .instagram-btn {
  background-color: #E1306C;
}

.social-media-buttons .email-btn {
  background-color: #ff9800;
}

/* File Sharing Section */
.file-sharing {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1c1c1e;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);
  max-width: 600px;
  width: 90%;
  margin: 30px auto;
}

.file-sharing input {
  padding: 10px;
  margin-top: 15px;
  background-color: #333;
  color: white;
  border: 1px solid #444;
  border-radius: 8px;
  width: 100%;
}

.file-sharing .upload-btn {
  padding: 12px 25px;
  background-color: #ff9800;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin-top: 15px;
  width: 100%;
}

.file-sharing .upload-btn:hover {
  background-color: #ff6f00;
  transform: scale(1.05);
}

/* Footer */
footer {
  background-color: #222;
  color: #888;
  padding: 20px 0;
  text-align: center;
  border-top: 1px solid #333;
  font-size: 0.9rem;
}

/* Add Smooth Scroll */
html {
  scroll-behavior: smooth;
}
