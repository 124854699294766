body {
    background-color: #1c1c1e;
}

.container {
    background-color: #2e2e2e;
    padding: 20px;
    border-radius: 10px;
}

.bg-dark-gray {
    background-color: #333;
}

.bg-dark-header {
    background-color: #444;
    color: #ddd;
}

.bg-dark-input {
    background-color: #1f1f1f;
    color: #ccc;
    border: 1px solid #555;
}

.bg-dark-hover:hover {
    background-color: #444;
}

input, select, button {
    color: #ddd;
}

button:hover {
    cursor: pointer;
}

.table-actions button {
    margin: 0 5px;
}

img:hover {
    transition: all 0.3s ease-in-out;
    transform: scale(1.1);
}
