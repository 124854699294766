/* Global Styles */
body {
    background-color: #1a1a2e;
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    margin: 0;
    padding: 0;
}

/* Container */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background: #222831;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

/* Header */
h2 {
    color: #00adb5;
    text-align: center;
    margin-bottom: 20px;
}

/* Form Styling */
form {
    margin-bottom: 30px;
}

form .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 15px;
}

input, select, textarea {
    background: #393e46;
    border: none;
    border-radius: 5px;
    color: #eeeeee;
    padding: 10px;
    outline: none;
    transition: background 0.3s ease;
}

input:focus, select:focus, textarea:focus {
    background: #4e535e;
}

textarea {
    resize: none;
}

button {
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
}

/* Button Colors */
.bg-green-500 {
    background-color: #2ecc71;
}

.bg-green-500:hover {
    background-color: #27ae60;
    transform: translateY(-2px);
}

.bg-blue-500 {
    background-color: #3498db;
}

.bg-blue-500:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
}

.bg-red-500 {
    background-color: #e74c3c;
}

.bg-red-500:hover {
    background-color: #c0392b;
    transform: translateY(-2px);
}

.bg-yellow-500 {
    background-color: #f1c40f;
}

.bg-yellow-500:hover {
    background-color: #f39c12;
    transform: translateY(-2px);
}

/* Table Styling */
table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

thead {
    background: #34495e;
}

thead th {
    padding: 10px;
    text-align: left;
    color: #ffffff;
}

tbody td {
    padding: 10px;
    border-bottom: 1px solid #444444;
    color: #dddddd;
}

/* Hover Effects */
tr:hover {
    background: #2c3e50;
    transition: background 0.2s ease;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .container {
        padding: 15px;
    }

    form .grid {
        grid-template-columns: 1fr;
    }

    table {
        font-size: 14px;
    }

    button {
        width: 100%;
        margin-top: 10px;
    }
}

/* Header Buttons for Upload/Download */
button[type="submit"] {
    display: inline-block;
    margin-right: 10px;
}

button:last-of-type {
    display: inline-block;
    margin-right: 0;
}

img {
    width: 25px;
    height: 25px;
    vertical-align: middle;
    margin-right: 8px;
}

/* Placeholder Styling */
input::placeholder, textarea::placeholder {
    color: #aaaaaa;
}

/* Alert Styling */
.alert {
    background-color: #444444;
    color: #eeeeee;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
    text-align: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

/* Icon Fonts (Using FontAwesome) */
button .fa {
    margin-right: 5px;
}

.fa-edit {
    color: #3498db;
}

.fa-trash {
    color: #e74c3c;
}

/* Hover effects for icons */
.fa-edit:hover {
    color: #2980b9;
}

.fa-trash:hover {
    color: #c0392b;
}

/* Table Action Buttons */
.table-actions button {
    background: transparent;
    border: none;
    padding: 5px;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.table-actions button:hover {
    transform: scale(1.1);
}

/* Custom Scrollbar */
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    background-color: #222831;
}
